<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="日期">
              <a-date-picker style="width: 100%" format="YYYY-MM-DD" :disabled-date="disabledDate" v-model="queryDate"
                showToday placeholder="Select Time" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="标题">
              <a-input v-decorator="[
                'title',
                {
                  initialValue: queryParam.title
                }
              ]" placeholder="用户名" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
              <a @click="toggleAdvanced" style="margin-left: 8px">
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'" />
              </a>
              <a-button style="float: right" @click="goAddForum">添加帖子</a-button>
            </span>
          </a-col>
          <template v-if="advanced">
            <a-col :md="8" :sm="24">
              <a-form-item label="是否置顶">
                <a-select placeholder="请选择" v-decorator="[
                  'is_top',
                  {
                    initialValue: queryParam.is_top
                  }
                ]">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="1">置顶</a-select-option>
                  <a-select-option value="0">非置顶</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态">
                <a-select placeholder="请选择" v-decorator="[
                  'status',
                  {
                    initialValue: queryParam.status
                  }
                ]">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">未发布</a-select-option>
                  <a-select-option value="1">发布中</a-select-option>
                  <a-select-option value="2">被禁</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="account_id" slot-scope="text, record">
        <a v-if="record.user_type === 'user'" :href="'/clients/detail?id=' + text">{{ record.nick_name }}</a>
        <a v-if="record.user_type === 'teacher'" :href="'/teacher/detail?id=' + text">{{ record.nick_name }}</a>
        <a v-if="record.user_type === 'admin'" :href="'/config/admin/edit?id=' + text">{{ record.nick_name }}</a>
      </span>
      <span v-if="text !== 0" slot="create_time" slot-scope="text">
        {{ text | dayjs }}
      </span>
      <span slot="is_top" slot-scope="text">
        <a-badge v-if="text == '1'" status="success" text="置顶中" />
        <a-badge v-if="text == '0'" status="default" text="普通" />
      </span>
      <span slot="status" slot-scope="text">
        <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
      </span>
      <span slot="action" slot-scope="text, record">
        <!-- 管理员的帖子才可编辑 -->
        <a v-if="record.user_type === 'admin'" @click="goEditForum(record)">编辑</a>
        <a-divider v-if="record.user_type === 'admin'" type="vertical" />
        <a @click="goShowForumInfo(record)">查看</a>
        <a-divider type="vertical" />
        <a-popconfirm v-if="data.length" title="确定删除该帖子?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
  </a-card>
</template>
<script>
import { DatePicker as ADatePicker } from 'ant-design-vue'
import { forumList, forumDelete } from '@/api/forum'
import merge from 'webpack-merge'
import moment from 'moment'

const statusMap = {
  '1': {
    status: 'success',
    text: '展示中'
  },
  '2': {
    status: 'default',
    text: '被关闭'
  },
  '0': {
    status: 'default',
    text: '未发布'
  }
}

const dateConfig = {
  rules: [{ type: 'object', required: true, message: 'Please select time!' }]
}

const columns = [
  {
    sorter: true,
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '用户类型',
    dataIndex: 'user_type'
  },
  {
    title: '用户ID',
    dataIndex: 'account_id',
    scopedSlots: { customRender: 'account_id' }
  },
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '是否置顶',
    dataIndex: 'is_top',
    sorter: true,
    scopedSlots: { customRender: 'is_top' }
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '发布时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  components: {
    ADatePicker
  },
  data() {
    return {
      description: '系统活动日志',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      dateConfig: dateConfig,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        title: this.$route.query.title,
        is_top: this.$route.query.is_top,
        status: this.$route.query.status
      },
      // 高级搜索 展开/关闭
      advanced: false,
      dateFormat: 'YYYY-MM-DD',
      date: '',
      queryDate: null,
      data: [],
      pagination: {},
      loading: false,
      columns,
      submitLoading: false,
      tableLoading: false
    }
  },
  created() {
    this.date = this.$route.query.date
  },
  mounted() {
    const params = this.$route.query
    this.fetch(params)
  },
  watch: {
    date(val) {
      if (val) {
        this.queryDate = moment(val, this.dateFormat)
      }
    },
    queryDate(val) {
      console.log(val)
      if (val) {
        this.date = val.format('YYYY-MM-DD')
      } else {
        this.date = ''
      }
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day')
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // 带上查询参数
      const queryParam = this.form.getFieldsValue()
      if (this.date) {
        queryParam.date = this.date
      }
      const params = {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      }
      this.fetch(params)
      this.$router.push({
        query: merge({}, params)
      })
    },
    fetch(params) {
      console.log(params)
      this.loading = true
      new Promise((resolve, reject) => {
        forumList(params).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        console.log(data)
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.list.data
        pagination.total = data.list.total
        pagination.pageSize = data.list.per_page
        pagination.current = data.list.current_page
        this.pagination = pagination
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({
        title: '',
        date: ''
      })
      this.queryDate = null
      this.date = ''
      if (this.advanced) {
        this.form.setFieldsValue({
          is_top: '',
          status: ''
        })
      }
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.fetch()
      this.submitLoading = false
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitLoading = true
          if (this.date) {
            values.date = this.date
          }
          this.fetch(values)
          this.submitLoading = false
          this.$router.push({
            query: merge({}, values)
          })
        }
      })
    },
    goEdit(record) {
      this.$router.push({ 'name': 'ClientsDetail', query: { id: record.id } })
    },
    goAddForum() {
      this.$router.push({ 'name': 'InformationForumEdit' })
    },
    goEditForum(record) {
      this.$router.push({ 'name': 'InformationForumEdit', query: { id: record.id } })
    },
    goShowForumInfo(record) {
      this.$router.push({ 'name': 'InformationForumInfo', query: { id: record.id } })
    },
    onDelete(id) {
      new Promise((resolve, reject) => {
        forumDelete({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        // 删除成功
        this.$notification.success({
          message: res.status,
          description: res.message
        })
        this.loadData()
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      })
    }
  }
}
</script>
